<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      debtors: [],
      Creditors: [],
      total: [],
      accountingPeriods: [],
      amount: 0,
      page: 1,
      limit: 20,
      totalPages: 0,
      totalCurrent: 0,
      totalTwoMonth: 0,
      totalThreeMonth: 0,
      twoMonth: [],
      data: {},
    };
  },
  methods: {
    getDebtors(page) {
      this.http
        .post("transactions/creditor-report", {
          accounting_period_id: this.data.accounting_period_id,
          limit: this.limit,
          page,
        })
        .then((res) => {
          this.totalCurrent = 0;
          this.totalTwoMonth = 0;
          this.totalThreeMonth = 0;
          this.debtors = res.data;
          this.debtors.forEach((debtor) => {
            if (debtor.one_month == null) {
              debtor.one_month = 0;
            }
            if (debtor.two_month == null) {
              debtor.two_month = 0;
            }
            if (debtor.three_month == null) {
              debtor.three_month = 0;
            }
            this.totalCurrent += parseFloat(debtor.one_month);
            this.totalTwoMonth += parseFloat(debtor.two_month);
            this.totalThreeMonth += parseFloat(debtor.three_month);
          });
        });
      console.log(this.totalTwoMonth);
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
  },
  created() {
    this.getDebtors(1);
    this.getAccountingPeriods();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('reports.creditors')"
    />
    <!-- <form @submit.prevent="getCreditors()">
      <div class="row">
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.period")
          }}</label>
          <select
            v-model="data.accounting_period_id"
            type="date"
            class="form-control"
            id="fromJobTitle"
          >
            <option
              :value="period.id"
              v-for="period in accountingPeriods"
              :key="period"
            >
              {{ $t("report.period") + " : " + period?.start_date }}
            </option>
          </select>
        </div>
        <div class="col-auto" style="top: 20px">
          <button type="submit" class="btn btn-primary">
            {{ $t("popups.search") }}
          </button>
        </div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
    </form> -->
    <div class="row mt-4">
      <div class="card">
        <div class="card-body">
          <ReportsHeader :reportName="$t('reports.creditors')" />

          <div class="table-responsive">
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <!-- <th>{{ $t("Creditor.code") }}</th> -->
                  <th>{{ $t("Debtors.name") }}</th>
                  <th>{{ $t("Debtors.befor30") }}</th>
                  <th>{{ $t("Debtors.befor60") }}</th>
                  <th>{{ $t("Debtors.befor90") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="debtor in debtors" :key="debtor">
                  <!-- <td>{{ creditor.code }}</td> -->
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? debtor.name
                        : debtor.name_en
                        ? debtor.name_en
                        : debtor.name
                    }}
                  </td>
                  <td>
                    <span>{{ debtor.one_month }}</span>
                  </td>
                  <td>
                    <span>{{ debtor.two_month }}</span>
                  </td>
                  <td>
                    <span>{{ debtor.three_month }}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="total-style">
                    {{ totalCurrent.toLocaleString() }}
                  </td>
                  <td class="total-style">
                    {{ totalTwoMonth.toLocaleString() }}
                  </td>
                  <td class="total-style">
                    {{ totalThreeMonth.toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
</style>
